const ResumeSection = () => {
  return (
    <div className="section resume" id="section-history">
      <div className="content">
        <div className="cols">
          <div className="col col-md">
            {/* title */}
            <div className="title">
              <div className="title_inner">Experience</div>
            </div>
            {/* resume items */}
            <div className="resume-items">
              <div className="resume-item content-box active">
                <div className="date">2022 - Present</div>
                <div className="name">Software Engineer - Panorama ID (Panorama JTB).</div>
                <div className="text">
                  Backend and CMS Developer for Panorama ID Application, a travel companion app.
                </div>
              </div>
              <div className="resume-item content-box">
                <div className="date">2019 - 2021</div>
                <div className="name">Backend Developer - Maelsov Mega Teknologi</div>
                <div className="text">
                  Part of the Backend Team whose works on many projects.
                </div>
              </div>
              <div className="resume-item content-box">
                <div className="date">Aug - Oct 2020</div>
                <div className="name">Backend Developer - Triputre Adi Persada</div>
                <div className="text">
                  As a shadow developer for the backend application and API.
                </div>
              </div>
              <div className="resume-item content-box">
                <div className="date">Sept - Oct 2020</div>
                <div className="name">Backend Developer - JCO</div>
                <div className="text">
                  As a shadow developer for the backend application.
                </div>
              </div>
              <div className="resume-item content-box">
                <div className="date">June - July 2020</div>
                <div className="name">Backend Developer - Wilmar Consultancy Service</div>
                <div className="text">
                  As a shadow developer for the backend application.
                </div>
              </div>
              <div className="resume-item content-box">
                <div className="date">May - June 2020</div>
                <div className="name">Backend Developer - CV Besi Brebes Jaya</div>
                <div className="text">
                  As a shadow developer for the backend application.
                </div>
              </div>
              <div className="resume-item content-box">
                <div className="date">2020 - 2021</div>
                <div className="name">Backend Developer - Alpha Go</div>
                <div className="text">
                  As a backend developer for the api.
                </div>
              </div>
              <div className="resume-item content-box">
                <div className="date">2019 - 2020</div>
                <div className="name">Software Engineer - Imani Primary Care</div>
                <div className="text">
                  As a Backend and Frontend Developer for the Company Profile Application.
                </div>
              </div>
            </div>
          </div>
          <div className="col col-md">
            {/* title */}
            <div className="title">
              <div className="title_inner">Education</div>
            </div>
            {/* resume items */}
            <div className="resume-items">
              <div className="resume-item content-box">
                <div className="date">2014 - 2021</div>
                <div className="name">UIN SGB Bandung - Bandung, Indonesia</div>
                <div className="text">
                  Bachelors Degree in Informatic Engineering UIN Sunan Gunung Djati,
                  Bandung, Indonesia.
                </div>
              </div>
              <div className="resume-item content-box">
                <div className="date">2011 - 2014</div>
                <div className="name">Highschool - Bekasi, Indonesia</div>
                {/* <div className="text">
                  
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResumeSection;
