import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="copy">
        <p><span className='icon fas fa-globe'/> : <Link to="https://arenvy.com">https://arenvy.com</Link></p>
        <p><span className='icon fas fa-envelope'/> : <Link to="mailto:bayudevara@gmail.com">bayudevara@gmail.com</Link></p>
        <p><span className='icon fas fa-phone'/> : <Link to="https://wa.me/6287885958453">+62 878 8595 8453</Link></p>
        <p><span className='icon fab fa-github'/> : <Link to="https://github.com/bayudvr">bayudvr</Link></p>
      </div>
      <div className="soc-box">
        <div className="follow-label">Follow Me</div>
        <div className="soc">
          <Link target="_blank" rel="noreferrer" to="https://www.instagram.com/bayudvr">
            <span className="icon fab fa-instagram" />
          </Link>
          {/* <Link target="_blank" rel="noreferrer" to="https://www.youtube.com/@m2xsquad320">
            <span className="icon fab fa-youtube" />
          </Link> */}
          <Link target="_blank" rel="noreferrer" to="https://twitter.com/bayudvr">
            <span className="icon fab fa-twitter" />
          </Link>
          <Link target="_blank" rel="noreferrer" to="https://www.wattpad.com/user/cielo597">
            <span className="icon fas fa-book" />
          </Link>
        </div>
      </div>
      <div className="clear" />
    </footer>
  )
}

export default Footer